import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import PostItem from "../components/post/item"

export default ({ data }) => (
  <Layout>
    <h2>Articoli</h2>
    <div className="latest-articles">
      {data.allMarkdownRemark.edges.map(({ node }) => (
        <PostItem key={node.id} post={node} type="latest" />
      ))}
    </div>
    <div className="columns">
      <div className="b-box">
        <h3>Anlysis e consultancy</h3>
        <p>
          Analisi e ottimizzazione delle prestazioni del sito internet;
          valutazione dell'usabilità e A/B testing; progettazione di
          architetture CSS, design system e coding guidelines;
        </p>
      </div>
      <div className="b-highlight">
        <h3>Training</h3>
        <p>
          Negli anni ho avuto la possibilità di organizzare e tenere diversi
          corsi di formazione: sviluppo siti web, gestione di siti in TYPO3,
          sviluppo temi per TYPO3 e Magento.
          <br />
          Ho partecipato come <Link to="speaker">speaker</Link> a diverse
          conferenze,
        </p>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY", locale: "it")
          }
          fields {
            slug
          }
          excerpt(pruneLength: 200)
        }
      }
    }
  }
`
