import React from "react"
import Meta from "../post/meta"
import Title from "../post/title"
import Content from "../post/content"
import { Link} from "gatsby"

const Wrapper = props => <article className="post-item">{props.children}</article>
const Header = props => <header className="post-item__header">{props.children}</header>

export default ({post, type}) => (
    <Wrapper>
        <Header>
            <Meta text={post.frontmatter.date}/>
            <Title text={post.frontmatter.title} slug={post.fields.slug} type={type}/>
        </Header>
        <Content text={post.excerpt}/>
        <p><Link to={post.fields.slug}>Leggi ancora...</Link></p>
    </Wrapper>
)